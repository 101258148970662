<div class="button button-{{size}} button-{{theme}}" [class.disabled]="disabled">
  <ng-container *ngIf="icon">
    <ng-container *ngTemplateOutlet="icon"></ng-container>
  </ng-container>

  <div class="text">
    <ng-container *ngIf="!isLoading">
      <ng-content></ng-content>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <app-ui-icon [iconName]="'icon-spinning-bar-2'" class="spinning"></app-ui-icon>
    </ng-container>
  </div>
</div>
