import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAdditionalFormComponent } from './user-additional-form/user-additional-form.component';
import { ComponentsModule } from '../../ui-kit/components/components.module';
import { NavigationStepsHeaderComponent } from './user-additional-form/navigation-steps-header/navigation-steps-header.component';

@NgModule({
  declarations: [UserAdditionalFormComponent, NavigationStepsHeaderComponent],
  imports: [CommonModule, ComponentsModule],
  exports: [UserAdditionalFormComponent, NavigationStepsHeaderComponent],
})
export class SharedModule {}
