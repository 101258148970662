import {
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  Directive,
  ChangeDetectorRef,
  Injector,
} from '@angular/core';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  FormElementEventType,
  FormElementState,
  FormElementStateBuilder,
  FormElementStateChange,
  FormElementStateChangeBuilder,
  FormElementType,
  FormElementUniqueIdType,
  FormElementValidator,
  ValidationTrigger,
} from './form-element-types';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class FormElement<T, K> implements OnInit, OnDestroy, OnChanges {
  @Input() public id: FormElementUniqueIdType | undefined;
  @Input() public validators: Array<FormElementValidator<T, K>> = [];
  @Input() public validationTriggers: Array<ValidationTrigger> =
    FormElement.getDefaultValidationTriggers();
  @Input() public value: T | undefined;
  @Input() public isHideErrorText = false;
  @Input() public disabled: boolean = false;

  @Output() public valueInputChange: EventEmitter<T> = new EventEmitter();
  @Output() public valueChange: EventEmitter<T> = new EventEmitter();
  @Output() public blurChange: EventEmitter<void> = new EventEmitter();
  @Output() public focusChange: EventEmitter<void> = new EventEmitter();
  @Output() public touchChange: EventEmitter<boolean> = new EventEmitter();
  @Output() public stateChange: EventEmitter<FormElementStateChange<T>> = new EventEmitter();
  @Output() public validationChange: EventEmitter<boolean> = new EventEmitter();

  public el: ElementRef;
  public cdr: ChangeDetectorRef;

  public state: FormElementState<T> = new FormElementStateBuilder<T>(null, null, null);

  public abstract type: FormElementType;

  protected destroyEmitter = new EventEmitter();

  public static generateUniqueId(meta?: string): FormElementUniqueIdType {
    return Symbol(meta);
  }

  public static getDefaultValidationTriggers(): Array<ValidationTrigger> {
    return [FormElementEventType.ValueChangeEvent];
  }

  protected constructor(public injector: Injector) {
    this.el = injector.get(ElementRef);
    this.cdr = injector.get(ChangeDetectorRef);
  }

  public ngOnInit(): void {
    this.registerValidationTriggers();
    this.registerStateListeners();
    this.validate();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { id, type, value } = this;
    const isValueChanged = changes['value'] && changes['value']?.previousValue !== value;

    this.updateState({ id, type, value });

    if (isValueChanged) {
      this.validate();
      this.valueInputChange.emit(value);
    }
  }

  public ngOnDestroy(): void {
    this.destroyEmitter.emit();
  }

  protected onFocus(): void {
    if (this.disabled) {
      return;
    }

    this.updateState({ isFocused: true });
    this.focusChange.emit();
  }

  protected onBlur(): void {
    this.updateState({ isFocused: false });
    this.blurChange.emit();
  }

  public emulateTouch(): void {
    this.onTouch();
    this.cdr.detectChanges();
  }

  protected onTouch(): void {
    if (!this.state.isTouched) {
      this.updateState({ isTouched: true });
      this.setErrorDisplay();
    }

    this.touchChange.emit();
  }

  protected onValueChange(value: T): void {
    this.updateState({ value });
    this.value = value;
    this.valueChange.emit(value);
  }

  protected onValidationChange(): void {
    this.validationChange.emit(this.state.isValid);
  }

  protected updateState(updated: Partial<FormElementState<T>>): void {
    this.state = { ...this.state, ...updated };
  }

  protected registerStateListeners(): void {
    const observableEvents = new Map<FormElementEventType, EventEmitter<any>>([
      [FormElementEventType.ValueChangeEvent, this.valueChange],
      [FormElementEventType.TouchEvent, this.touchChange],
      [FormElementEventType.FocusEvent, this.focusChange],
      [FormElementEventType.BlurEvent, this.blurChange],
    ]);

    observableEvents.forEach((eventEmitter: EventEmitter<any>, type: FormElementEventType) => {
      this.registerStateEventListener(eventEmitter, type);
    });
  }

  private registerStateEventListener(
    eventEmitter: EventEmitter<any>,
    type: FormElementEventType,
  ): void {
    eventEmitter.pipe(takeUntil(this.destroyEmitter)).subscribe(() => {
      this.stateChange.emit(this.getStateChange(type));
    });
  }

  private getStateChange(type: FormElementEventType): FormElementStateChange<T> {
    return new FormElementStateChangeBuilder<T>(type, this.state);
  }

  protected registerValidationTriggers(): void {
    const streams = [];

    for (const trigger of this.validationTriggers) {
      switch (trigger) {
        case FormElementEventType.FocusEvent:
          streams.push(this.focusChange);
          break;
        case FormElementEventType.BlurEvent:
          streams.push(this.blurChange);
          break;
        case FormElementEventType.TouchEvent:
          streams.push(this.touchChange);
          break;
        case FormElementEventType.ValueChangeEvent:
          streams.push(this.valueChange);
          break;
        default:
          if (trigger instanceof EventEmitter) {
            streams.push(trigger);
          }
      }
    }

    merge(...streams)
      .pipe(takeUntil(this.destroyEmitter))
      .subscribe(() => {
        this.validate();
        this.cdr.markForCheck();
      });
  }

  public validate(): string | null {
    if (this.disabled) {
      this.updateValidationState();
      return null;
    }

    if (!this.validators || !this.validators.length) {
      this.updateValidationState();

      return null;
    }

    for (const validator of this.validators) {
      const result = validator(this.state.value, this as unknown as K);

      if (typeof result === 'string') {
        this.updateValidationState(result);

        return result;
      }
    }

    this.updateValidationState();

    return null;
  }

  private updateValidationState(errorText: string | null = null): void {
    this.updateState({ isValid: !Boolean(errorText), errorText });
    this.onValidationChange();
  }

  protected setErrorDisplay() {
    this.updateState({ isShowError: true });
  }

  isShowError() {
    return (
      !this.state.isFocused &&
      !this.isHideErrorText &&
      !this.state.isValid &&
      this.state.isShowError
    );
  }
}

/**
 * Модель ассоциативной связи "элемент <-> значение" через уникальный ID
 */
export class FormElementModel<T> {
  public id: FormElementUniqueIdType;

  constructor(
    public value: T,
    associativeText?: string,
  ) {
    this.id = FormElement.generateUniqueId(associativeText);
  }
}
