<div class="tags-input">
  <ul id="tags">
    <li *ngFor="let tag of tags" [class.error]="tag.isError" [tooltip]="'Needs to be >5 length'" [tooltipDisabled]="!tag.isError">
      <span class="label label-sm">
        {{ tag.value }}
      </span>
      <div class="close-icon" (click)="removeTag(tag)">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.18306 5.18306C5.42714 4.93898 5.82286 4.93898 6.06694 5.18306L10 9.11612L13.9331 5.18306C14.1771 4.93898 14.5729 4.93898 14.8169 5.18306C15.061 5.42714 15.061 5.82286 14.8169 6.06694L10.8839 10L14.8169 13.9331C15.061 14.1771 15.061 14.5729 14.8169 14.8169C14.5729 15.061 14.1771 15.061 13.9331 14.8169L10 10.8839L6.06694 14.8169C5.82286 15.061 5.42714 15.061 5.18306 14.8169C4.93898 14.5729 4.93898 14.1771 5.18306 13.9331L9.11612 10L5.18306 6.06694C4.93898 5.82286 4.93898 5.42714 5.18306 5.18306Z" fill="#57738B"/>
        </svg>
      </div>
    </li>
  </ul>
  <input id="input-tag" autocomplete="off" (keydown)="onKeyDown($event)" [(ngModel)]="inputValue" placeholder="Enter NIB" />
</div>
