<div class="drop-container" appDnd (fileDropped)="onFileDropped($event)" *ngIf="view === 'circle'">
  <div class="container" [style.background-image]="previewUrl ? 'url(' + previewUrl + ')' : null">
    <input
      id="fileDropRefCircle"
      type="file"
      accept="image/*"
      (change)="fileBrowseHandler($event)"
    />
    <label for="fileDropRefCircle" class="label">
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        *ngIf="!previewUrl"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.25 15C19.6642 15 20 15.3358 20 15.75V18H22.25C22.6642 18 23 18.3358 23 18.75C23 19.1642 22.6642 19.5 22.25 19.5H20V21.75C20 22.1642 19.6642 22.5 19.25 22.5C18.8358 22.5 18.5 22.1642 18.5 21.75V19.5H16.25C15.8358 19.5 15.5 19.1642 15.5 18.75C15.5 18.3358 15.8358 18 16.25 18H18.5V15.75C18.5 15.3358 18.8358 15 19.25 15Z"
          fill="#57738B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5 15.75C14.1569 15.75 15.5 14.4069 15.5 12.75C15.5 11.0931 14.1569 9.75 12.5 9.75C10.8431 9.75 9.5 11.0931 9.5 12.75C9.5 14.4069 10.8431 15.75 12.5 15.75ZM12.5 17.25C14.9853 17.25 17 15.2353 17 12.75C17 10.2647 14.9853 8.25 12.5 8.25C10.0147 8.25 8 10.2647 8 12.75C8 15.2353 10.0147 17.25 12.5 17.25Z"
          fill="#57738B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.06066 3.87868C9.62327 3.31607 10.3863 3 11.182 3H13.818C14.6137 3 15.3767 3.31607 15.9393 3.87868L16.8713 4.81066C17.1526 5.09196 17.5342 5.25 17.932 5.25H18.5C20.9853 5.25 23 7.26472 23 9.75V12.75C23 13.1642 22.6642 13.5 22.25 13.5C21.8358 13.5 21.5 13.1642 21.5 12.75V9.75C21.5 8.09315 20.1569 6.75 18.5 6.75H17.932C17.1363 6.75 16.3733 6.43393 15.8107 5.87132L14.8787 4.93934C14.5974 4.65804 14.2158 4.5 13.818 4.5H11.182C10.7842 4.5 10.4026 4.65804 10.1213 4.93934L9.18934 5.87132C8.62673 6.43393 7.86367 6.75 7.06802 6.75H6.5C4.84315 6.75 3.5 8.09315 3.5 9.75V16.5C3.5 18.1569 4.84315 19.5 6.5 19.5H11.75C12.1642 19.5 12.5 19.8358 12.5 20.25C12.5 20.6642 12.1642 21 11.75 21H6.5C4.01472 21 2 18.9853 2 16.5V9.75C2 7.26472 4.01472 5.25 6.5 5.25H7.06802C7.46584 5.25 7.84738 5.09196 8.12868 4.81066L9.06066 3.87868Z"
          fill="#57738B"
        />
      </svg>

      <div class="reg-s" *ngIf="!previewUrl">Upload</div>
    </label>
  </div>
</div>

<ng-container *ngIf="view === 'button'">
  <input
    id="fileDropRefButton"
    type="file"
    accept="image/*"
    (change)="fileBrowseHandler($event)"
  />
  <label for="fileDropRefButton">
    <app-ui-button
      appDnd
      (fileDropped)="onFileDropped($event)"
      [theme]="'primary'"
      [size]="'small'"
      *ngIf="view === 'button'"
    >
      <ng-content></ng-content>
    </app-ui-button>
  </label>
</ng-container>
