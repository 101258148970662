<div class="container">
  <app-navigation-steps-header [active]="2"></app-navigation-steps-header>

  <h1 class="page_title">Finish Your Profile</h1>

  <span class="description">
    Add a few details to enjoy personalized content tailored just for you
  </span>

  <ui-form-container [schema]="formState" (submit)="submit($event)">
    <app-ui-input
      [size]="'large'"
      [label]="'Name'"
      [placeholder]="'John Doe'"
      [id]="formState.name.id"
      [disabled]="!!user?.name"
      [value]="formState.name.value"
      [validators]="[requiredValidator]"
    ></app-ui-input>

    <app-ui-input
      [size]="'large'"
      [label]="'Phone number'"
      [placeholder]="'085228882401'"
      [inputType]="'number'"
      [disabled]="!!user?.phone"
      [id]="formState.phone.id"
      [value]="formState.phone.value"
      [validators]="[requiredValidator]"
    ></app-ui-input>

    <app-ui-input
      [size]="'large'"
      [label]="'Company'"
      [placeholder]="'PT Best Company'"
      [id]="formState.company.id"
      [disabled]="!!user?.company"
      [value]="formState.company.value"
      [validators]="[requiredValidator]"
    ></app-ui-input>

    <app-ui-input
      [size]="'large'"
      [label]="'Job'"
      [placeholder]="'Appraiser'"
      [id]="formState.job.id"
      [disabled]="!!user?.role"
      [value]="formState.job.value"
      [validators]="[requiredValidator]"
    ></app-ui-input>

    <div class="grow"></div>

    <app-ui-button [fullWidth]="true" [theme]="'primary'" [size]="'medium'" uiFormContainerButton>
      Get Started
    </app-ui-button>
  </ui-form-container>
</div>
