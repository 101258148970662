import { Component } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { WorkspaceBoardsStore } from '../../pages/workspace/data-access/workspace-boards.store';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-authorized-proxy-component',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './authorized-proxy-component.component.html',
  providers: [provideComponentStore(WorkspaceBoardsStore)],
})
export class AuthorizedProxyComponent {}
