import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BoardsApiService } from '../../shared/services/api/boards-api.service';
import { catchError, first, of } from 'rxjs';
import { ComponentsModule } from '../../shared/ui-kit/components/components.module';
import { LogoComponent } from '../../shared/components/logo/logo.component';
import { NgIf } from '@angular/common';
import { IconName } from '../../shared/ui-kit/components/ui-icon-x/ui-icon-x.component';

@Component({
  selector: 'app-share-board-proxy',
  standalone: true,
  imports: [ComponentsModule, LogoComponent, NgIf],
  templateUrl: './share-board-proxy.component.html',
  styleUrl: './share-board-proxy.component.less',
})
export class ShareBoardProxyComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private boardsApi: BoardsApiService,
  ) {}
  ngOnInit() {
    const boardId = this.route.snapshot.paramMap.get('id');

    if (!boardId) {
      this.router.navigate(['workspace']);
      return;
    }

    this.boardsApi
      .joinById(boardId)
      .pipe(
        first(),
        catchError(() => {
          console.log('302 ERROR NOT FIXED, CALL IVAN');
          return of(null);
        }),
      )
      .subscribe((it) => {
        this.router.navigate(['board', boardId]);
      });
  }

  protected readonly IconName = IconName;
}
