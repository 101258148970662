<p class="normalize-button heading-sm">{{ modalData.board.name }}</p>

<div class="row">
  <div class="left">Name</div>
  <div class="right">
    <app-ui-input [value]="modalData.board.name" #inputComponent></app-ui-input>
  </div>
</div>
<div class="row">
  <div class="left">Owner</div>
  <div class="right">
    {{ getBoardAuthor()?.name || 'Unknown' }}
  </div>
</div>
<div class="row">
  <div class="left">Created</div>
  <div class="right">{{ modalData.board.createdAt | date }}</div>
</div>
<div class="row">
  <div class="left">Location</div>
  <div class="right">
    <ng-container *ngIf="modalData.board.users.length > 1">
      Shared across {{ modalData.board.users.length }} users
    </ng-container>
    <ng-container *ngIf="modalData.board.users.length === 1">
      Personal
    </ng-container>
  </div>
</div>
<app-ui-button [theme]="'primary'" style="width: 100%" (click)="submit()">Submit</app-ui-button>
