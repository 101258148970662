import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appDnd]',
  standalone: true,
})
export class DropFileDirective {
  @HostBinding('class.fileover') fileOver: boolean = false;
  @Output() fileDropped = new EventEmitter<FileList>();

  // Dragover listener
  @HostListener('document:dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.fileOver) {
      this.fileOver = true;
    }
  }

  // Dragleave listener
  @HostListener('document:dragleave', ['$event']) public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.fileOver) {
      this.fileOver = false;
    }
  }

  // Drop listener
  @HostListener('document:drop', ['$event']) public onFieldDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.fileOver) {
      this.fileOver = false;
    }

    let files = evt.dataTransfer.files as FileList;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
