import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ui-image-picker',
  templateUrl: './ui-image-picker.component.html',
  styleUrls: ['./ui-image-picker.component.less'],
})
export class UiImagePickerComponent {
  public previewUrl: string | null = null;
  @Input() view: 'circle' | 'button' = 'circle';
  @Output() imageLoaded = new EventEmitter<File>();

  public onFileDropped(fileList: FileList) {
    if (fileList.length > 1) {
      console.error('only single file allowed');
      return;
    }

    if (!fileList[0].type.startsWith('image/')) {
      console.error('only images allowed');
      return;
    }

    this.loadImage(fileList[0]);
  }

  public fileBrowseHandler(event: any) {
    const fileList = event.target.files;

    if (fileList.length > 1) {
      console.error('only single file allowed');
      return;
    }

    if (!fileList[0].type.startsWith('image/')) {
      console.error('only images allowed');
      return;
    }

    this.loadImage(fileList[0]);
  }

  public loadImage(file: File) {
    if (!file) {
      return;
    }

    this.previewUrl = URL.createObjectURL(file);
    this.imageLoaded.emit(file);
  }
}
