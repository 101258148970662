import { Injectable } from '@angular/core';
import { IUser } from 'src/app/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SaveLocalService {
  constructor() {}

  setUser(data: IUser): void {
    localStorage.setItem('prr-user', JSON.stringify(data));
  }

  getUser(): IUser {
    let user = localStorage.getItem('prr-user');
    return user ? JSON.parse(user) : null;
  }

  deleteUser(): void {
    localStorage.removeItem('prr-user');
  }
}
