import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-navigation-steps-header',
  templateUrl: './navigation-steps-header.component.html',
  styleUrls: ['./navigation-steps-header.component.less'],
})
export class NavigationStepsHeaderComponent {
  @Input() active: 1 | 2 = 1;
}
