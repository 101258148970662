import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormElementType } from '../ui-form/form-element-types';

export type Tag = {
  value: number;
  isError: boolean;
};
@Component({
  selector: 'app-ui-tag-input',
  templateUrl: './ui-tag-input.component.html',
  styleUrls: ['./ui-tag-input.component.less'],
})
export class UiTagInputComponent {
  public type: FormElementType = FormElementType.TagInput;
  public isValid = true;

  private isTagValid = (v: number) => true;

  @Input() tags: Tag[] = [];
  @Output() tagsChange = new EventEmitter<Tag[]>();

  public inputValue: string = '';
  public onKeyDown(evt: KeyboardEvent) {
    if (evt.key !== ' ') {
      return;
    }

    evt.preventDefault();
    this.tags.push({ value: Number(this.inputValue), isError: false });
    this.inputValue = '';
    this.validate();
  }

  public validate() {
    for (const tag of this.tags) {
      tag.isError = !this.isTagValid(tag.value);
    }
  }

  public removeTag(tag: Tag) {
    const index = this.tags.findIndex((it) => it === tag);

    this.tags.splice(index, 1);
  }
}
