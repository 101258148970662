import { Component, Input } from '@angular/core';

export enum IconName {
  Close = 'Close',
  PriceTag = 'PriceTag',
  SquareMeasurement = 'SquareMeasurement',
  Locations = 'Locations',
  Info = 'Info',
  Warning = 'Warning',
  List = 'List',
  Map = 'Map',
  Stamp = 'Stamp',
  Lifesaver = 'Lifesaver',
  Plus = 'Plus',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Search = 'Search',
  Location = 'Location',
  Layers = 'Layers',
  MapFilters = 'MapFilters',
  Polygon = 'Polygon',
  ThreeD = 'ThreeD',
  Home = 'Home',
  OverflowMenuVertical = 'OverflowMenuVertical',
  Globe = 'Globe',
  Settings = 'Settings',
  CaretUp = 'CaretUp',
  BankCard = 'BankCard',
  Wallet = 'Wallet',
  InOut = 'InOut',
  Code = 'Code',
  MulticolorSelectTrue = 'MulticolorSelectTrue',
  MulticolorSelectFalse = 'MulticolorSelectFalse',
  ProgressBar = 'ProgressBar',
  StarWithBorder = 'StarWithBorder',
  UserGroups = 'UserGroups',
  User = 'User',
  Share = 'Share',
  Watch = 'Watch',
  Notification = 'Notification',
  Copy = 'Copy',
  Perare = 'Perare',
  ChevronDownM = 'ChevronDownM',
  ChartCandle = 'ChartCandle',
  ChartLine = 'ChartLine',
  View = 'View',
  ViewOff = 'ViewOff',
  Focus = 'Focus',
}
@Component({
  selector: 'app-ui-icon-x',
  templateUrl: './ui-icon-x.component.html',
  styleUrls: ['./ui-icon-x.component.less'],
})
export class UiIconXComponent {
  public IconName = IconName;

  @Input() iconName: string = '';
  @Input() size: number = 16;
  @Input() color: string = '#0E1217';
}
