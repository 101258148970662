import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { UiButtonComponent } from './ui-button/ui-button.component';
import { UiIconComponent } from './ui-icon/ui-icon.component';
import { UiSelectComponent } from './ui-select/ui-select.component';
import { NzModule } from '../../modules';
import { UiCounterComponent } from './ui-counter/ui-counter.component';
import { FormsModule } from '@angular/forms';
import { UiTabsComponent } from './ui-tabs/ui-tabs.component';
import { UiSwitcherComponent } from './ui-switcher/ui-switcher.component';
import { UiFormComponent } from './ui-form/ui-form/ui-form.component';
import { UiInputComponent } from './ui-form/ui-input/ui-input.component';
import { FormContainerComponent } from './ui-form/form-container/form-container.component';
import { FormContainerButtonDirective } from './ui-form/form-container/form-container-button/form-container-button.directive';
import { UiFilePickerComponent } from './ui-file-picker/ui-file-picker.component';
import { ProgressComponent } from './ui-file-picker/progress/progress.component';
import { UiImagePickerComponent } from './ui-image-picker/ui-image-picker.component';
import { UiTagInputComponent } from './ui-tag-input/ui-tag-input.component';
import { TooltipModule } from './tooltip/tooltip.module';
import { UiIconXComponent } from './ui-icon-x/ui-icon-x.component';
import { UiTagComponent } from './ui-tag/ui-tag.component';
import { UiDoubleAvatarComponent } from './ui-double-avatar/ui-double-avatar.component';
import { UiIconButtonComponent } from './ui-icon-button/ui-icon-button.component';
import { DropFileDirective } from '../../directives/drop-file.directive';

@NgModule({
  declarations: [
    UiButtonComponent,
    UiIconComponent,
    UiSelectComponent,
    UiCounterComponent,
    UiTabsComponent,
    UiSwitcherComponent,
    UiInputComponent,
    UiFormComponent,
    FormContainerComponent,
    FormContainerButtonDirective,
    UiFilePickerComponent,
    ProgressComponent,
    UiImagePickerComponent,
    UiTagInputComponent,
    UiIconXComponent,
    UiTagComponent,
    UiDoubleAvatarComponent,
    UiIconButtonComponent,
  ],
  exports: [
    UiButtonComponent,
    UiIconComponent,
    UiSelectComponent,
    UiCounterComponent,
    UiTabsComponent,
    UiSwitcherComponent,
    UiInputComponent,
    FormContainerComponent,
    FormContainerButtonDirective,
    UiFilePickerComponent,
    UiImagePickerComponent,
    UiTagInputComponent,
    UiIconXComponent,
    UiTagComponent,
    UiDoubleAvatarComponent,
    UiIconButtonComponent,
    ProgressComponent,
  ],
  imports: [
    CommonModule,
    NzModule,
    FormsModule,
    TooltipModule,
    NgOptimizedImage,
    DropFileDirective,
  ],
})
export class ComponentsModule {}
