import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { TokenService } from '../pages/auth/services';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ErrorHandlerService } from '../shared/services/error-handler.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private token: TokenService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !request.url.includes('auth') &&
      !request.url.includes('registration') &&
      !request.url.includes('social') &&
      !request.url.includes('password') &&
      !request.url.includes('geoserver') &&
      !request.url.includes('search/ownershiptype/list') &&
      !request.url.includes('search/desa/list') &&
      !request.url.includes('search/kabupaten/list')
    ) {
      if (this.token.checkToken()) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token.getToken()}`,
          },
        });
        return next.handle(request).pipe(
          catchError((err: any) => {
            if (err.error.code === 401 && !request.url.includes('password')) {
              this.errorHandler.handleError(err);
              this.router.navigate(['auth']);
            }
            return throwError(err);
          }),
        );
      }
    }
    return next.handle(request);
  }
}
