import { ComponentStore, OnStoreInit, tapResponse } from '@ngrx/component-store';
import { Company } from '../shared/services/api/company-api.service';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, exhaustMap, map, Observable, pipe, tap } from 'rxjs';
import { IUser } from '../interfaces';
import { SaveLocalService } from '../pages/auth/services';
import { Route, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SettingsService } from '../shared/services/api/settings.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../shared/services/error-handler.service';
import { NzMessageService } from 'ng-zorro-antd/message';

export type SelectedWorkspaceState = {
  activeWorkspace: 'company' | 'user';
  company: Company | null;
  user: IUser | null;
};

export type UpdateUserPayload = {
  name: string;
  company: string;
  role: string;
  avatar?: string;
  locale?: string;
  phone: string;
};

@Injectable({
  providedIn: 'root',
})
export class SelectedWorkspaceStore extends ComponentStore<SelectedWorkspaceState> {
  public company$ = this.select((state) => state.company, { debounce: true });
  public user$ = this.select((state) => state.user, { debounce: true });
  public activeWorkspace$ = this.select((state) => state.activeWorkspace, { debounce: true });

  public storeState$ = this.select({
    activeWorkspace: this.activeWorkspace$,
    company: this.company$,
    user: this.user$,
  });

  public activeWorkspaceEntity$ = this.storeState$.pipe(
    map((state) => {
      if (state.activeWorkspace === 'company') {
        return state.company;
      }
      if (state.activeWorkspace === 'user') {
        return state.user;
      }

      return null;
    }),
  );
  public isProActive$ = this.select(
    (state) => {
      if (state.company) {
        return false;
      }

      return !!state.user?.proIsActive;
    },
    { debounce: true },
  ).pipe(distinctUntilChanged());

  setCompany = this.updater((state, company: Company | null) => ({
    ...state,
    company,
    activeWorkspace: company !== null ? 'company' : 'user',
  }));

  public setUser = this.updater((state, user: IUser) => {
    return { ...state, user: user };
  });

  constructor(
    private local: SaveLocalService,
    private settings: SettingsService,
    private router: Router,
    private location: Location,
    private errorHandler: ErrorHandlerService,
    private message: NzMessageService,
  ) {
    super({ company: null, user: null, activeWorkspace: 'user' });

    this.init();
  }

  private init() {
    const savedUser = this.local.getUser();

    if (savedUser) {
      this.setUser(savedUser);
    }
  }

  getStateSync(): SelectedWorkspaceState {
    return this.get();
  }

  public setCurrentCompany = this.effect((company$: Observable<Company | null>) => {
    return company$.pipe(
      tap((company) => this.setCompany(company)),
      tap((company) => {
        if (company !== null) {
          if (this.location.path().includes('workspace/user')) {
            this.router.navigate(['workspace/company']);
          }
        } else {
          if (this.location.path().includes('workspace/company')) {
            this.router.navigate(['workspace/user']);
          }
        }
      }),
    );
  });

  refreshUser = this.effect<void>(
    pipe(
      exhaustMap(() => {
        return this.settings.infoUser().pipe(
          tapResponse(
            (user: IUser) => {
              this.setUser(user);
              this.local.setUser(user);
            },
            (err) => {
              console.error(err);
            },
          ),
        );
      }),
    ),
  );

  public updateUserData = this.effect<UpdateUserPayload>((args$) => {
    return args$.pipe(
      exhaustMap((args) => {
        return this.settings.updateUser(args).pipe(
          tapResponse(
            (success) => {
              this.refreshUser();
              this.message.success('User info updated');
            },
            (err) => {
              this.errorHandler.handleError(err);
            },
          ),
        );
      }),
    );
  });

  public getUserSync() {
    return this.get().user;
  }

  public getProIsActiveSync(): boolean {
    const state = this.get();

    if (state.company) {
      return false;
    }

    return !!state.user?.proIsActive;
  }

  public clearState() {
    this.setState({ company: null, user: null, activeWorkspace: 'user' });
  }
}
