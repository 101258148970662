import { Component, EventEmitter, inject, Output } from '@angular/core';
import { FormElementModel } from '../../../ui-kit/components/ui-form/form-element';
import { AppStore } from '../../../../stores/app-store';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GTrack } from '../../../../tracker';
import { takeUntil } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IUser } from '../../../../interfaces';
import { SelectedWorkspaceStore } from '../../../../stores/selected-workspace.store';
import { SettingsService } from '../../../services/api/settings.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { BoardsApiService } from '../../../services/api/boards-api.service';
import { firstValueFrom } from 'rxjs';

export type FormValues = {
  name: string;
  phone: string;
  company: string;
  job: string;
};
@Component({
  selector: 'app-user-additional-form',
  templateUrl: './user-additional-form.component.html',
  styleUrls: ['./user-additional-form.component.less'],
})
export class UserAdditionalFormComponent {
  @Output() submitSuccess = new EventEmitter<void>();
  public requiredValidator = (v: any) => (!v ? 'Required' : null);
  private destroy$ = new EventEmitter<void>();

  public formState = {
    name: new FormElementModel(''),
    phone: new FormElementModel(''),
    company: new FormElementModel(''),
    job: new FormElementModel(''),
  };

  public user: IUser | null = null;

  constructor(
    private settingsService: SettingsService,
    private store: AppStore,
    private router: Router,
    private workspaceStore: SelectedWorkspaceStore,
    private errorHandler: ErrorHandlerService,
    private boardsApi: BoardsApiService,
  ) {
    this.user = this.workspaceStore.getUserSync();
  }

  ngOnInit() {
    this.formState = {
      name: new FormElementModel(this.user?.name || ''),
      phone: new FormElementModel(this.user?.phone || ''),
      company: new FormElementModel(this.user?.company || ''),
      job: new FormElementModel(this.user?.role || ''),
    };
  }
  ngOnDestroy() {
    this.destroy$.next();
  }

  public submit(event: any) {
    const formValues = event as FormValues;
    GTrack('registration_additional-info_submit', 'registration');
    this.settingsService
      .updateUser({
        name: formValues.name,
        phone: formValues.phone,
        company: formValues.company,
        role: formValues.job,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        async (it) => {
          const boards = await firstValueFrom(this.boardsApi.getBoardsList());
          const board = boards.find((it) => it.type === 'map');

          if (!board) {
            this.router.navigate(['/workspace']);
          } else {
            this.router.navigate(['board', board.id]);
          }

          this.submitSuccess.emit();
        },
        (err) => {
          this.errorHandler.handleError(err);
          return [];
        },
      );
  }
}
