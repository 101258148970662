<div class="container">
  <input [ngModel]="value" (ngModelChange)="onValueChange($event)" (keydown)="onKeyDown($event)">

  <div class="increment-buttons">
    <div class="btn inc" [class.disabled]="value >= max" (click)="onIncrement()">
      +
    </div>
    <div class="separator"></div>
    <div class="btn dec" [class.disabled]="value <= min" (click)="onDecrement()">
      -
    </div>
  </div>
</div>
