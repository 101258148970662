import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingProxyComponent } from './listing-proxy/listing-proxy.component';



@NgModule({
  declarations: [
    ListingProxyComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ProxyModule { }
