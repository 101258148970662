import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ui-switcher',
  templateUrl: './ui-switcher.component.html',
  styleUrls: ['./ui-switcher.component.less'],
})
export class UiSwitcherComponent {
  @Input() value: boolean | null = false;
  @Input() disabled: boolean | null = false;
  @Output() valueChange = new EventEmitter<boolean>();

  public onSwitcherToggle() {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
