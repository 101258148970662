import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-form',
  templateUrl: './ui-form.component.html',
  styleUrls: ['./ui-form.component.less']
})
export class UiFormComponent {

}
