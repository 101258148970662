import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ui-double-avatar',
  templateUrl: './ui-double-avatar.component.html',
  styleUrls: ['./ui-double-avatar.component.less'],
})
export class UiDoubleAvatarComponent {
  @Input() mainAvatarUrl: string = '';
  @Input() secondaryAvatarUrl: string = '';
}
