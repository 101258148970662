import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';

const DEFAULT_ERR_MESSAGES: { [key: string]: string } = {
  '400': 'The server cannot process the request',
  '401': 'You are unauthorized',
  '403': 'You do not have access to this page',
  '404': 'Requested resource is not found',
  '413': 'Your data is too large',
  '429': 'You are sending too many requests. You are rate limited',
  '500': 'Internal server error acquired',
};
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private messageService: NzMessageService) {}

  handleError(error: any) {
    console.error(error);

    if (error instanceof Error) {
      this.notifyAboutError(error.message);
      return;
    }

    if (typeof error === 'string') {
      this.notifyAboutError(error);
      return;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error && error.error.message) {
        this.notifyAboutError(error.error.message);
        return;
      }

      this.notifyAboutDefaultError(error.status);
      return;
    }

    this.notifyAboutError('Error acquired, please try again later');
    return;
  }

  private notifyAboutError(errorMessage: string) {
    this.messageService.create('error', errorMessage);
  }

  private notifyAboutDefaultError(status: number) {
    const defaultMessage: string | undefined = DEFAULT_ERR_MESSAGES[status.toString()];

    if (defaultMessage) {
      this.notifyAboutError(defaultMessage);
      return;
    }

    this.notifyAboutError('Error acquired, please try again later');
  }
}
