import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ui-counter',
  templateUrl: './ui-counter.component.html',
  styleUrls: ['./ui-counter.component.less'],
})
export class UiCounterComponent {
  @Input() value: number = 0;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() max: number = 1000;
  @Input() min: number = 0;

  public onDecrement() {
    this.value--;
    this.valueChange.emit(this.value);
  }

  public onIncrement() {
    this.value++;
    this.valueChange.emit(this.value);
  }

  public onValueChange(e: string) {
    this.value = +e;
    this.valueChange.emit(+e);
  }

  onKeyDown($event: KeyboardEvent) {
    if ($event.defaultPrevented) {
      return;
    }

    let allowedSymbols = [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      0,
      'Backspace',
      'ArrowRight',
      'ArrowLeft',
      'ArrowUp',
      'ArrowDown',
    ].map((it) => String(it));

    if (allowedSymbols.indexOf($event.key) === -1) {
      $event.preventDefault();
    }
  }
}
