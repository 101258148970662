import { IconDefinition } from '@ant-design/icons-angular';

// Import what you need. RECOMMENDED. ✔️
import {
  LogoutOutline,
  MailOutline,
  ExclamationCircleOutline,
  DesktopOutline,
  ReadOutline,
  DatabaseOutline,
  UnorderedListOutline,
  ToolOutline,
  CloudServerOutline,
  SettingOutline,
  UserOutline,
  SolutionOutline,
  FileTextOutline,
  PlusCircleOutline,
  WarningOutline,
  RollbackOutline,
  CheckCircleOutline,
  ProfileOutline,
  DownloadOutline,
  EyeOutline,
  EyeInvisibleOutline,
  GlobalOutline,
  WifiOutline,
  UpOutline,
  MenuOutline,
  CreditCardOutline,
  HistoryOutline,
  ArrowRightOutline,
  ArrowLeftOutline,
  SyncOutline,
} from '@ant-design/icons-angular/icons';

export const icons: IconDefinition[] = [
  LogoutOutline,
  MailOutline,
  ExclamationCircleOutline,
  DesktopOutline,
  ReadOutline,
  DatabaseOutline,
  UnorderedListOutline,
  ToolOutline,
  CloudServerOutline,
  SettingOutline,
  UserOutline,
  SolutionOutline,
  FileTextOutline,
  PlusCircleOutline,
  WarningOutline,
  RollbackOutline,
  CheckCircleOutline,
  ProfileOutline,
  DownloadOutline,
  EyeOutline,
  EyeInvisibleOutline,
  GlobalOutline,
  WifiOutline,
  UpOutline,
  CreditCardOutline,
  HistoryOutline,
  UserOutline,
  MenuOutline,
  ArrowRightOutline,
  ArrowLeftOutline,
  SyncOutline,
];

// Import all. NOT RECOMMENDED. ❌
// import * as AllIcons from '@ant-design/icons-angular/icons';

// const antDesignIcons = AllIcons as {
//   [key: string]: IconDefinition;
// };
// export const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])
