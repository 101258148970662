import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ui-icon',
  templateUrl: './ui-icon.component.html',
  styleUrls: ['./ui-icon.component.less'],
})
export class UiIconComponent {
  @Input() iconName: string = '';
  @Input() size: number = 16;
}
