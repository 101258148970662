import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ui-tag',
  templateUrl: './ui-tag.component.html',
  styleUrls: ['./ui-tag.component.less'],
})
export class UiTagComponent {
  @Input() text = '';
  @Input() bgColor: string = '#E2E5EB';
  @Input() textColor: string = '#626D7C';
  @Input() borderColor: string = '#C3C9D1';
}
