import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ui-select',
  templateUrl: './ui-select.component.html',
  styleUrls: ['./ui-select.component.less'],
})
export class UiSelectComponent {
  @Input() label: string = '';
}
