import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, catchError, map, switchMap, of, tap, BehaviorSubject } from 'rxjs';
import { IUser } from 'src/app/interfaces';
import { SaveLocalService, TokenService } from 'src/app/pages/auth/services';
import { AppStore } from 'src/app/stores/app-store';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _userUrl: string = `${environment.apiUrl}/api/user`;

  constructor(
    private http: HttpClient,
    private store: AppStore,
    private errorHandler: ErrorHandlerService,
  ) {}

  infoUser(): Observable<IUser> {
    return this.http.get<IUser>(`${this._userUrl}/info`, {}).pipe(
      map((data: IUser) => {
        return data;
      }),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return [];
      }),
    );
  }

  updateUser(userData: Partial<IUser>): Observable<IUser> {
    this.store.user.value = {};
    return this.http
      .post<IUser>(`${this._userUrl}/info/update`, {
        ...userData,
      })
      .pipe(
        switchMap((data) => {
          return this.infoUser();
        }),
        catchError((err) => {
          this.errorHandler.handleError(err);
          return [];
        }),
      );
  }
}
