import { Injectable } from '@angular/core';
import { BehaviorSubjectItem } from './behavior-subject-item';
import { IUser, TLayer, TStyle } from '../interfaces';
import { TBaseInfo } from '../components/plot-profile/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AppStore {
  public isPayPending: BehaviorSubjectItem<boolean> = new BehaviorSubjectItem<boolean>(false);
  public initialPlotUUID: BehaviorSubjectItem<string> = new BehaviorSubjectItem('');
  public initialListingUUID: BehaviorSubjectItem<string> = new BehaviorSubjectItem('');
  public initialReferrerUUID: BehaviorSubjectItem<string> = new BehaviorSubjectItem('');
  public initialPointCoords: BehaviorSubjectItem<[number, number]> = new BehaviorSubjectItem([
    0, 0,
  ]);
  public initialZoneId: BehaviorSubjectItem<[string, string]> = new BehaviorSubjectItem(['', '']);
  public user: BehaviorSubjectItem<IUser | {}> = new BehaviorSubjectItem({});
  public plotBaseInfo: BehaviorSubjectItem<TBaseInfo | {}> = new BehaviorSubjectItem({});
  public snapshot: BehaviorSubjectItem<any> = new BehaviorSubjectItem('');
  public mapDataReady: BehaviorSubjectItem<boolean> = new BehaviorSubjectItem(false);
  public mapLayer: BehaviorSubjectItem<TLayer> = new BehaviorSubjectItem<TLayer>('basik');
  public mapStyle: BehaviorSubjectItem<TStyle> = new BehaviorSubjectItem<TStyle>('layout');
  public isSnapshotDontReady: BehaviorSubjectItem<boolean> = new BehaviorSubjectItem(false);

  constructor() {
    // this.isLoggedIn.value;
    //   this.history.value.load();
    //   this.filter.value.load();
  }
}
