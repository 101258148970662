import { ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AppStore } from '../stores/app-store';
import { TokenService } from '../pages/auth/services';

export const AuthGuard: CanActivateFn = (route, state) => {
  const store = inject(AppStore);
  const router = inject(Router);
  const tokenService = inject(TokenService);
  // const activatedRoute = inject(ActivatedRoute);

  if (route.queryParams['plot']) {
    store.initialPlotUUID.value = route.queryParams['plot'];
  }

  // if (route.queryParams['plot-listing']) {
  //   router.navigate([], {
  //     relativeTo: activatedRoute,
  //     queryParams: {},
  //   });
  // }

  if (tokenService.getToken()) {
    return true;
  } else {
    router.navigate(['auth']);
    return false;
  }
};
