<div class="tab-menu place-select">
  <div class="tabs-container" [class.is-loading]="isLoading">
    <div class="tabs">
      <div
        *ngFor="let tab of tabs"
        class="tab medium"
        [class.active]="selectedTab.label === tab.label"
        (click)="onTabSelect(tab)"
      >
        <div class="title">{{ tab.label }}</div>
      </div>
    </div>
  </div>
</div>
