import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormElement } from '../form-element';
import { FormElementType } from '../form-element-types';

@Component({
  selector: 'app-ui-input',
  templateUrl: './ui-input.component.html',
  styleUrls: ['./ui-input.component.less'],
  providers: [
    {
      provide: FormElement,
      useExisting: forwardRef(() => UiInputComponent),
    },
  ],
})
export class UiInputComponent extends FormElement<string | number, UiInputComponent> {
  private isManualFocus: boolean | null = null;
  readonly type: FormElementType = FormElementType.Input;

  @Input() public label = '';
  @Input() public maxLength = 200;
  @Input() public placeholder = '';
  @Input() public override disabled = false;
  @Input() set isFocused(isFocused: boolean) {
    this.isManualFocus = isFocused;
    this.updateState({ isFocused });
  }
  @Input() name = null;
  @Input() autocomplete = null;
  @Input() size: 'large' | 'medium' | 'small' = 'medium';
  @Input() inputType: 'number' | 'text' | 'password' = 'text';

  @ViewChild('inputElement') inputElement: ElementRef | null = null;

  constructor(public override injector: Injector) {
    super(injector);
  }

  resetValue() {
    this.onValueChange('');
  }

  protected override onFocus(): void {
    super.onFocus();
  }

  onValueChangeInput($event: any) {
    this.onValueChange($event);
  }

  onFocusInput() {
    if (this.isManualFocus === false) {
      return;
    }

    this.onFocus();
    this.onTouch();
  }

  onBlurInput() {
    if (this.isManualFocus) {
      return;
    }

    this.onBlur();
  }

  public isEmpty(): boolean {
    return this.value === '';
  }
}
