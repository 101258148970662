import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, map, catchError, switchMap, tap, of, EMPTY, repeat } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAuthResponse, ISignInForm, IVerifyUser } from '../interfaces';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppStore } from 'src/app/stores/app-store';
import { IUserCredentials } from '../interfaces/user-credentials.interface';
import { SaveLocalService, TokenService } from '.';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from 'src/app/interfaces';
import { SelectedWorkspaceStore } from '../../../stores/selected-workspace.store';
import { ErrorHandlerService } from '../../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _baseUrl: string = `${environment.apiUrl}/api`;
  private _userUrl: string = `${environment.apiUrl}/user`;

  private errorHandler = inject(ErrorHandlerService);

  constructor(
    private http: HttpClient,
    private store: AppStore,
    private token: TokenService,
    private router: Router,
    private local: SaveLocalService,
    private message: NzMessageService,
    private selectedWorkspace: SelectedWorkspaceStore,
  ) {}

  signIn(signInForm: ISignInForm): Observable<IUser> {
    return this.http.post<IAuthResponse>(`${this._baseUrl}/login_check`, signInForm).pipe(
      switchMap((data) => {
        if (data.token) {
          this.token.setToken(data.token);
        }

        return this.http.get<IUser>(`${this._baseUrl}/user/info`, {});
      }),
      tap((info: IUser) => {
        this.selectedWorkspace.setUser(info);
      }),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return EMPTY;
      }),
    );
  }

  newUser(registerForm: { email: string }): Observable<any> {
    return this.http.post(`${this._userUrl}/new`, registerForm).pipe(
      map((data) => {
        // this.store.isLoggedIn.value = true;
        return data;
      }),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return [];
      }),
    );
  }

  registrationUserData(credentials: IUserCredentials): Observable<any> {
    return this.http.post(`${this._userUrl}/new-pass`, credentials).pipe(
      map((data) => {
        // this.store.isLoggedIn.value = true;
        this.message.create('success', `Registration Success! Now you can log in.`);
        return data;
      }),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return [];
      }),
    );
  }

  recoveryUserPass(recoveryForm: { email: string }): Observable<any> {
    return this.http.post(`${this._userUrl}/new-pass/init`, recoveryForm).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return [];
      }),
    );
  }

  verifyUser(verifyForm: IVerifyUser): Observable<any> {
    return this.http.post(`${this._userUrl}/verify-code`, verifyForm).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return [];
      }),
    );
  }
}
