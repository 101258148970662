import { EventEmitter } from '@angular/core';

export type FormElementValidator<T, K> = (value: T | null, context?: K) => string | null;

export type FormElementUniqueIdType = Symbol;

export enum FormElementType {
  Input = 'Input',
  TagInput = 'TagInput',
}

export interface FormElementState<T> {
  id: FormElementUniqueIdType | null;
  type: FormElementType | null;
  value: T | null;
  isValid: boolean;
  isTouched: boolean;
  isFocused: boolean;
  isShowError: boolean;
  errorText: string | null;
}

export class FormElementStateBuilder<T> implements FormElementState<T> {
  public isValid = false;
  public isTouched = false;
  public isFocused = false;
  public isShowError = false;
  public errorText: string | null = null;

  constructor(
    public id: FormElementUniqueIdType | null,
    public type: FormElementType | null,
    public value: T | null,
  ) {}
}

export enum FormElementEventType {
  ValueChangeEvent = 'ValueChangeEvent',
  ValidationChangeEvent = 'ValidationChangeEvent',
  TouchEvent = 'TouchEvent',
  FocusEvent = 'FocusEvent',
  BlurEvent = 'BlurEvent',
}

export type ValidationTrigger = FormElementEventType | EventEmitter<void>;

export interface FormElementStateChange<T> {
  id: FormElementUniqueIdType | null;
  type: FormElementEventType;
  currentState: FormElementState<T>;
}

export class FormElementStateChangeBuilder<T> implements FormElementStateChange<T> {
  public id: FormElementUniqueIdType | null = null;

  constructor(
    public type: FormElementEventType,
    public currentState: FormElementState<T>,
  ) {
    if (this.currentState) {
      this.id = this.currentState.id;
    }
  }
}
