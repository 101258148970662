import {
  FormElementState,
  FormElementStateChange,
  FormElementUniqueIdType,
} from './form-element-types';
import { FormElementModel } from './form-element';

export type FormElementStateMap = Map<FormElementUniqueIdType, FormElementState<any>>;

export type FormValue = string | boolean | number;

export type FormSchema =
  | FormElementModel<FormValue>
  | Array<FormSchema>
  | { [key: string]: FormSchema }
  | Array<{ [key: string]: FormSchema }>;

export type FormValues =
  | FormValue
  | Array<FormValue>
  | { [key: string]: FormValues }
  | Array<{ [key: string]: FormValues }>;

export class FormState {
  constructor(
    public isValid = false,
    public isTouched = false,
  ) {}
}

export class FormStateChange {
  constructor(
    public elementStateMap: FormElementStateMap,
    public state: FormState,
    public lastElementChange: FormElementStateChange<any>,
  ) {}
}
