import { ComponentStore, OnStoreInit, tapResponse } from '@ngrx/component-store';
import { catchError, EMPTY, exhaustMap, first, forkJoin, of, pipe, switchMap, tap } from 'rxjs';
import {
  CompaniesResponse,
  Company,
  CompanyApiService,
  CompanyCreatePayload,
} from '../../../shared/services/api/company-api.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Board, BoardsApiService } from '../../../shared/services/api/boards-api.service';
import { BoardEditModalComponent } from '../ui/board-edit-modal/board-edit-modal.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Utils } from '../../../shared/utils/utils';
import { SelectedWorkspaceStore } from '../../../stores/selected-workspace.store';

export type WorkspaceBoardsStoreState = {
  isLoading: boolean;
  boards: Board[];
};

@Injectable()
export class WorkspaceBoardsStore
  extends ComponentStore<WorkspaceBoardsStoreState>
  implements OnStoreInit
{
  public stateVm$ = this.select((state) => state, { debounce: true });
  private isMobileMenu = Utils.isMobile();
  public boards$ = this.select((state) => state.boards);

  constructor(
    private boardsApi: BoardsApiService,
    private message: NzMessageService,
    private modal: NzModalService,
    private selectedWorkspace: SelectedWorkspaceStore,
  ) {
    super({
      isLoading: true,
      boards: [],
    });
  }

  ngrxOnStoreInit() {
    this.init();
  }

  public init = this.effect<void>(
    pipe(
      tap(() => this.patchState({ isLoading: true })),
      exhaustMap(() =>
        this.boardsApi.getBoardsList().pipe(
          tapResponse(
            (res) => {
              this.patchState({ boards: res, isLoading: false });
            },
            (err) => {
              this.patchState({ isLoading: false });
              this.handleError(new Error('Error while loading boards list'));
            },
          ),
        ),
      ),
    ),
  );

  public onBoardUpdated(board: Board) {
    const state = this.get();

    const boards = state.boards;

    this.patchState({
      boards: boards.map((it) => {
        if (it.id !== board.id) {
          return it;
        }

        return board;
      }),
    });
  }

  // public createCompany = this.effect<CompanyCreatePayload>((args$) => {
  //   return args$.pipe(
  //     exhaustMap((args) => {
  //       return this.companyService.createCompany(args).pipe(
  //         tapResponse(
  //           (boar) => {
  //             this.patchState({ boards: [...this.get().companies, company] });
  //           },
  //           () => {
  //             this.handleError(new Error('Error while creating company'));
  //           },
  //         ),
  //       );
  //     }),
  //   );
  // });

  private handleError(error: Error) {
    console.error(error.message);
  }

  public editBoard = this.effect<Board>((board$) => {
    return board$.pipe(
      tap((board) => {
        const user = this.selectedWorkspace.getUserSync();

        if (!user) {
          return;
        }

        if (user.id !== board.authorId) {
          return;
        }

        const modal = this.modal.create({
          nzWidth: !this.isMobileMenu ? 418 : '100%',
          nzStyle: { bottom: '0px' },
          nzContent: BoardEditModalComponent,
          nzData: {
            board: board,
          },
          nzClosable: this.isMobileMenu,
          nzFooter: null,
        });

        modal.afterClose.pipe(first()).subscribe((it: Board) => {
          if (!it) {
            return;
          }

          this.boardsApi
            .updateBoard(it)
            .pipe(first())
            .subscribe(() => {
              this.onBoardUpdated(it);
              this.message.success('Board updated');
            });
        });
      }),
      catchError((err: any) => {
        console.error(err);
        return of([]);
      }),
    );
  });

  public createBoard = this.effect<void>((args$) => {
    return args$.pipe(
      exhaustMap(() => {
        return this.boardsApi
          .createBoard({
            name: 'Board',
          })
          .pipe(
            tapResponse(
              (res) => {
                this.init();
              },
              (e: Error) => {
                this.handleError(e);
              },
            ),
          );
      }),
    );
  });

  public deleteBoard = this.effect<Board>((args$) => {
    return args$.pipe(
      exhaustMap((board: Board) => {
        return this.boardsApi.deleteBoard(board).pipe(
          tapResponse(
            (res) => {
              this.patchState({
                boards: this.get().boards.filter((it) => it.id !== board.id),
              });
            },
            (e: Error) => {
              this.handleError(e);
            },
          ),
        );
      }),
    );
  });
}
