import { Component, Input } from '@angular/core';
import { IconName } from '../ui-icon-x/ui-icon-x.component';

@Component({
  selector: 'app-ui-icon-button',
  templateUrl: './ui-icon-button.component.html',
  styleUrls: ['./ui-icon-button.component.less'],
})
export class UiIconButtonComponent {
  @Input() iconName: IconName = IconName.Home;
  @Input() size: number = 16;
}
