import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, map, catchError } from 'rxjs';
import { AppStore } from 'src/app/stores/app-store';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class OauthService {
  private _baseUrl: string = `${environment.apiUrl}/oauth`;

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
  ) {}

  getToken(): Observable<any> {
    return this.http.get(`${this._baseUrl}/token/`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return [];
      }),
    );
  }

  getGoogleCb(): Observable<any> {
    return this.http.get(`${this._baseUrl}/google/`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return [];
      }),
    );
  }

  getGoogleConnect(): Observable<any> {
    return this.http.get(`${this._baseUrl}/google/connect`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return [];
      }),
    );
  }
}
