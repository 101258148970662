import { OwnershipByCode } from './consts';

export type OwnershipState = {
  [key: number]: {
    color: string;
    title: string;
    state: boolean;
    id: number;
  };
};

export enum BasikFeatureLayer {
  Basik = 'basik',
  Ownerships = 'ownerships',
  Rdtr = 'rdtr',
  Price = 'price',
}
