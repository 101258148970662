import { Component, Inject, ViewChild } from '@angular/core';
import { ComponentsModule } from '../../../../shared/ui-kit/components/components.module';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { Board } from '../../../../shared/services/api/boards-api.service';
import { DatePipe, NgIf } from '@angular/common';
import { UiInputComponent } from '../../../../shared/ui-kit/components/ui-form/ui-input/ui-input.component';

export type ModalArgs = {
  board: Board;
};
@Component({
  selector: 'app-board-edit-modal',
  standalone: true,
  imports: [ComponentsModule, DatePipe, NgIf],
  templateUrl: './board-edit-modal.component.html',
  styleUrl: './board-edit-modal.component.less',
})
export class BoardEditModalComponent {
  @ViewChild(UiInputComponent) input: UiInputComponent | null = null;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: ModalArgs,
    private modalRef: NzModalRef,
  ) {}

  public getBoardAuthor() {
    return this.modalData.board.users.find((it) => it.id === this.modalData.board.authorId) || null;
  }

  public submit() {
    if (!this.input) {
      this.modalRef.close(undefined);
      return;
    }

    const newName = this.input.value;

    this.modalRef.close({
      ...this.modalData.board,
      name: newName,
    });
  }
}
