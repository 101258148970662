import { Component, HostBinding, Input, TemplateRef } from '@angular/core';

export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonTheme = 'primary' | 'secondary' | 'select' | 'tertiary' | 'ghost';
@Component({
  selector: 'app-ui-button',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.less'],
})
export class UiButtonComponent {
  @Input() size: ButtonSize = 'medium';
  @Input() disabled: boolean = false;
  @Input() theme: ButtonTheme = 'primary';
  @Input() isLoading: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() icon: TemplateRef<any> | null = null;

  @HostBinding('style.width') get width() {
    return this.fullWidth ? '100%' : 'initial';
  }
}
