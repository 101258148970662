import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {}

  setToken(token: string): void {
    localStorage.setItem('auth_token', token);
  }

  checkToken(): boolean {
    return localStorage.getItem('auth_token') ? true : false;
  }

  getToken(): string | null {
    return localStorage.getItem('auth_token') ? localStorage.getItem('auth_token') : null;
  }

  deleteToken(): void {
    localStorage.removeItem('auth_token');
  }
}
