import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AuthorizedProxyComponent } from './components/authorized-proxy-component/authorized-proxy-component.component';
import { ShareBoardProxyComponent } from './components/share-board-proxy/share-board-proxy.component';
import { ListingProxyComponent } from './pages/proxy/listing-proxy/listing-proxy.component';

const routes: Routes = [
  { path: '', redirectTo: 'workspace', pathMatch: 'full' },
  { path: 'map', redirectTo: 'workspace', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'board-join/:board_id/:listing_id',
    component: ListingProxyComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: AuthorizedProxyComponent,
    children: [
      {
        path: '',
        redirectTo: 'workspace',
        pathMatch: 'full',
      },
      {
        path: 'board/:uid',
        loadChildren: () => import('./pages/dp-map/dp-map.module').then((m) => m.DpMapModule),
      },
      {
        path: 'workspace',
        loadChildren: () =>
          import('./pages/workspace/workspace.module').then((m) => m.WorkspaceModule),
      },
      {
        path: 'super-user',
        loadChildren: () =>
          import('./pages/super-user/super-user.module').then((m) => m.SuperUserModule),
      },
      {
        path: 'board-share/:id',
        component: ShareBoardProxyComponent,
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/error/error.module').then((m) => m.ErrorModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
