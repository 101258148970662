import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FormContainerButtonDirective } from './form-container-button/form-container-button.directive';
import { FormElement, FormElementModel } from '../form-element';
import { UiButtonComponent } from '../../ui-button/ui-button.component';
import { FormElementStateChange, FormElementUniqueIdType } from '../form-element-types';
import {
  FormElementStateMap,
  FormSchema,
  FormState,
  FormStateChange,
  FormValue,
  FormValues,
} from '../form-types';

@Component({
  selector: 'ui-form-container',
  templateUrl: './form-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormContainerComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(FormElement, { descendants: true }) public formElements: QueryList<
    FormElement<any, any>
  > = new QueryList<FormElement<any, any>>();
  @ContentChild(FormContainerButtonDirective, { static: false, read: ElementRef })
  public formButton: ElementRef | null = null;
  @ContentChild(FormContainerButtonDirective, { static: false, read: UiButtonComponent })
  public formButtonInstance: UiButtonComponent | null = null;

  @Input() schema: FormSchema = {};

  @Output() public formStateChange: EventEmitter<FormStateChange> = new EventEmitter();
  @Output() public submit: EventEmitter<FormValues> = new EventEmitter();

  public state = new FormState();

  private readonly formElementStateListeners: Map<FormElementUniqueIdType, Subscription> =
    new Map();

  private readonly destroyEmitter: EventEmitter<void> = new EventEmitter();

  ngAfterContentInit(): void {
    this.registerInnerListeners();
    this.checkFormState(this.getFormElementStateMap());

    this.formElements.changes
      .pipe(takeUntil(this.destroyEmitter))
      .subscribe(() => this.updateInnerListeners());
  }

  ngOnDestroy(): void {
    this.destroyEmitter.emit();
  }

  public submitForm(): void {
    this.formElements.forEach((element) => {
      element.validate();
      element.emulateTouch();
    });

    this.checkFormState(this.getFormElementStateMap());

    if (!this.state.isValid) {
      const firstErrorElement = this.formElements.find((it) => !it.state.isValid);
      if (firstErrorElement) {
        firstErrorElement.el.nativeElement.scrollIntoView();
      }

      return;
    }

    const formValues = this.applySchema();

    this.submit.emit(formValues);
  }

  private applySchema(): FormValues {
    return this.getValues(this.schema, this.getFormElementStateMap());
  }

  private getValues(schema: FormSchema, formElementStateMap: FormElementStateMap): FormValues {
    const isModel = schema instanceof FormElementModel;
    const isNotEmptyObject = typeof schema === 'object' && !isModel && Object.keys(schema).length;
    const isArray = typeof schema === 'object' && Array.isArray(schema);

    if (schema === null || typeof schema === 'undefined') {
      throw new Error('schema undefined');
    }

    if (isModel) {
      const elementId = (schema as FormElementModel<FormValue>).id;
      const elementState = formElementStateMap.get(elementId);

      return elementState ? elementState.value : null;
    }

    if (isArray) {
      const arr: any[] = [];

      (schema as Array<FormSchema>).forEach((item) => {
        const value = this.getValues(item, formElementStateMap);

        if (value !== null && typeof value !== 'undefined') {
          arr.push(value);
        }
      });

      if (arr.length) {
        return arr;
      }

      throw new Error('Schema empty');
    }

    if (isNotEmptyObject) {
      const obj = {};

      Object.keys(schema).forEach((key) => {
        const value = this.getValues(schema[key], formElementStateMap);

        if (value !== null && typeof value !== 'undefined') {
          // @ts-ignore
          obj[key] = value;
        }
      });

      if (Object.keys(obj).length) {
        return obj;
      }
    }

    throw new Error('Schema error');
  }

  private createFormStateChange(lastElementChange: FormElementStateChange<any>): FormStateChange {
    const formElementStateMap = this.getFormElementStateMap();
    const formState = this.checkFormState(formElementStateMap);

    return new FormStateChange(formElementStateMap, formState, lastElementChange);
  }

  private getFormElementStateMap(): FormElementStateMap {
    return this.formElements
      .toArray()
      .reduce((result, { state }) => result.set(state.id, state), new Map());
  }

  private registerInnerListeners(): void {
    for (const elementInstance of this.formElements.toArray()) {
      this.addElementStateListener(elementInstance);
    }

    if (this.formButton instanceof ElementRef) {
      this.addFormButtonClickListener();
    }
  }

  private updateInnerListeners(): void {
    const formElementStateMap = this.getFormElementStateMap();
    let isChanges = false;

    for (const elementInstance of this.formElements.toArray()) {
      if (!this.formElementStateListeners.has(elementInstance.id || Symbol())) {
        this.addElementStateListener(elementInstance);
        isChanges = true;
      }
    }

    this.formElementStateListeners.forEach((listener, id) => {
      if (!formElementStateMap.has(id)) {
        this.removeElementStateListener(id);
        isChanges = true;
      }
    });

    if (isChanges) {
      this.checkFormState(formElementStateMap);
    }
  }

  private addFormButtonClickListener(): void {
    fromEvent(this.formButton?.nativeElement, 'click')
      .pipe(
        takeUntil(this.destroyEmitter),
        filter(() => !this.formButtonInstance?.disabled),
      )
      .subscribe(() => {
        this.submitForm();
      });
  }

  private addElementStateListener(elementInstance: FormElement<any, any>): void {
    const listener = elementInstance.stateChange
      .pipe(takeUntil(this.destroyEmitter))
      .subscribe((state) => this.onFormElementStateChange(state));

    this.formElementStateListeners.set(elementInstance.id || Symbol(), listener);
  }

  private removeElementStateListener(formElementId: FormElementUniqueIdType): void {
    const listener = this.formElementStateListeners.get(formElementId);

    if (listener) {
      listener.unsubscribe();
    }

    this.formElementStateListeners.delete(formElementId);
  }

  private onFormElementStateChange(elementStateChange: FormElementStateChange<any>): void {
    const formStateChange = this.createFormStateChange(elementStateChange);

    this.updateState(formStateChange);
    this.formStateChange.emit(formStateChange);
  }

  private updateState({ state: { isValid, isTouched } }: FormStateChange): void {
    this.state = new FormState(isValid, isTouched);
  }

  private checkFormState(formElementStateMap: FormElementStateMap): FormState {
    const states = Array.from(formElementStateMap.values());
    const isFormValid = states.every(({ isValid }) => isValid);
    const isFormTouched = states.some(({ isTouched }) => isTouched);

    return (this.state = new FormState(isFormValid, isFormTouched));
  }
}
