import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStore } from '../../../stores/app-store';
import { SelectedWorkspaceStore } from '../../../stores/selected-workspace.store';

@Component({
  selector: 'app-listing-proxy',
  templateUrl: './listing-proxy.component.html',
  styleUrls: ['./listing-proxy.component.less'],
})
export class ListingProxyComponent {
  constructor(
    private route: ActivatedRoute,
    private selectedWorkspaceStore: SelectedWorkspaceStore,
    private router: Router,
  ) {
    const boardId = this.route.snapshot.paramMap.get('board_id');
    const listingId = this.route.snapshot.paramMap.get('listing_id');

    if (!this.selectedWorkspaceStore.getUserSync()) {
      this.router.navigate(['auth'], {
        queryParams: {
          boardId,
          listingId,
        },
      });
    } else {
      this.router.navigate(['board-share', boardId]);
    }
  }
}
