<div class="container" appDnd (fileDropped)="onFileDropped($event)">
  <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5458_11587)">
      <path
        d="M30.5 25L29.086 23.586L26.5 26.172V18H24.5V26.172L21.914 23.586L20.5 25L25.5 30L30.5 25Z"
        fill="#57738B"
      />
      <path
        d="M18.5 28.0003H8.5V4.00034H16.5V10.0003C16.5015 10.5303 16.7127 11.0381 17.0875 11.4129C17.4622 11.7876 17.97 11.9988 18.5 12.0003H24.5V15.0003H26.5V10.0003C26.5036 9.86891 26.4786 9.73827 26.4268 9.61742C26.375 9.49657 26.2976 9.38839 26.2 9.30034L19.2 2.30034C19.112 2.20267 19.0038 2.12527 18.8829 2.07347C18.7621 2.02168 18.6314 1.99673 18.5 2.00034H8.5C7.97004 2.00187 7.46222 2.21308 7.08748 2.58782C6.71274 2.96256 6.50153 3.47038 6.5 4.00034V28.0003C6.50153 28.5303 6.71274 29.0381 7.08748 29.4129C7.46222 29.7876 7.97004 29.9988 8.5 30.0003H18.5V28.0003ZM18.5 4.40034L24.1 10.0003H18.5V4.40034Z"
        fill="#57738B"
      />
    </g>
  </svg>

  <div class="bold-s">Drop your files here or <label for="fileDropRef">Browse for file</label></div>
</div>
<div class="files-list">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div class="file-icon">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.75 8.25V20.75C18.75 21.3023 18.3023 21.75 17.75 21.75H6.25C5.69772 21.75 5.25 21.3023 5.25 20.75V3.25C5.25 2.69772 5.69772 2.25 6.25 2.25H12.75M18.75 8.25L12.75 2.25M18.75 8.25H13.75C13.1977 8.25 12.75 7.80228 12.75 7.25V2.25"
          stroke="#57738B"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="body">
      <div class="title semi-bold-xs">
        {{ file?.name }}
      </div>
      <div class="info">
        <div class="left">
          <p class="reg-s">
            {{ formatBytes(file?.size) }}
          </p>
        </div>
        <div class="right">
          <div class="reg-s progress-text" *ngIf="file?.progress < 100">
            {{ file?.progress }}%
          </div>
          <div class="reg-s progress-done" *ngIf="file?.progress >= 100">
            Done
          </div>
        </div>
      </div>
      <app-progress *ngIf="file?.progress < 100" [progress]="file?.progress"></app-progress>
    </div>

    <div class="delete" (click)="deleteFile(i)">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM8.78033 7.71967C8.48744 7.42678 8.01256 7.42678 7.71967 7.71967C7.42678 8.01256 7.42678 8.48744 7.71967 8.78033L10.9393 12L7.71967 15.2197C7.42678 15.5126 7.42678 15.9874 7.71967 16.2803C8.01256 16.5732 8.48744 16.5732 8.78033 16.2803L12 13.0607L15.2197 16.2803C15.5126 16.5732 15.9874 16.5732 16.2803 16.2803C16.5732 15.9874 16.5732 15.5126 16.2803 15.2197L13.0607 12L16.2803 8.78033C16.5732 8.48744 16.5732 8.01256 16.2803 7.71967C15.9874 7.42678 15.5126 7.42678 15.2197 7.71967L12 10.9393L8.78033 7.71967Z" fill="#ACBDCC"/>
      </svg>
    </div>
  </div>
</div>
