import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { TokenService } from './pages/auth/services';
import { SelectedWorkspaceStore } from './stores/selected-workspace.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  public title = 'perare-main';

  constructor(
    private selectedWorkspace: SelectedWorkspaceStore,
    private token: TokenService,
    public vcr: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    if (this.token.checkToken()) {
      this.selectedWorkspace.refreshUser();
    }
  }
}
