import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { TokenService } from '../pages/auth/services';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStore } from '../stores/app-store';
import { Company } from '../shared/services/api/company-api.service';
import { takeUntil } from 'rxjs/operators';
import { SelectedWorkspaceStore } from '../stores/selected-workspace.store';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  private currentCompany: Company | null = null;
  constructor(
    private token: TokenService,
    private route: ActivatedRoute,
    private router: Router,
    private workspaceStore: SelectedWorkspaceStore,
    private appStore: AppStore,
  ) {
    this.workspaceStore.storeState$.subscribe((it) => {
      this.currentCompany = it.company;
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.currentCompany && !this.appStore.initialReferrerUUID.value) {
      return next.handle(request);
    }

    if (
      this.currentCompany &&
      !request.url.includes('auth') &&
      !request.url.includes('registration') &&
      !request.url.includes('social') &&
      !request.url.includes('password') &&
      !request.url.includes('geoserver.dev')
    ) {
      const company = this.currentCompany;
      const uuid = company.uuid;

      request = request.clone({
        setHeaders: {
          'x-company': uuid,
        },
      });
    }

    if (
      this.appStore.initialReferrerUUID.value &&
      !request.url.includes('auth') &&
      !request.url.includes('registration') &&
      !request.url.includes('social') &&
      !request.url.includes('password') &&
      !request.url.includes('geoserver.dev')
    ) {
      request = request.clone({
        setHeaders: {
          'x-referer-link': this.appStore.initialReferrerUUID.value,
        },
      });
    }
    return next.handle(request);
  }
}
