<div class="container">
  <div class="label" *ngIf="label || state.errorText">
    {{ label }}

    <div class="caption" *ngIf="isShowError() && state.errorText">
      {{ state.errorText }}
    </div>
  </div>

  <div class="input" [class.error]="!state.isFocused && state.errorText && state.isTouched" [ngClass]="size">
    <input
      [placeholder]="!state.isFocused && placeholder || ''"
      [disabled]="disabled"
      [ngModel]="value"
      (ngModelChange)="onValueChangeInput($event)"
      (focus)="onFocusInput()"
      (blur)="onBlurInput()"
      [type]="inputType"
      [name]="name || ''"
      #inputElement/>

    <ng-content select="[inputRight]"></ng-content>
  </div>
</div>
