import { Component, EventEmitter, Input, Output } from '@angular/core';

export type UiTab = {
  label: string;
  id: number | string;
};

@Component({
  selector: 'app-ui-tabs',
  templateUrl: './ui-tabs.component.html',
  styleUrls: ['./ui-tabs.component.less'],
})
export class UiTabsComponent {
  @Input() tabs: UiTab[] = [];
  @Input() isLoading: boolean = false;

  @Input() selectedTab: UiTab = { label: '', id: '' };
  @Output() selectedTabChange = new EventEmitter<UiTab>();

  public onTabSelect(tab: UiTab) {
    this.selectedTab = tab;
    this.selectedTabChange.emit(tab);
  }
}
