import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map, Observable } from 'rxjs';
import { ListingInfo } from './listing-api.types';
import { Position } from 'geojson';
import { ExternalEntity, ExternalEntityCreatePayload } from './types/external-entities.types';
import { LayersEnableMap } from '../../../pages/dp-map/data-access/dp-map.store';
import { BasikFeatureLayer } from '../../../pages/dp-map/utils/types';

export type DetailedPlotSettings = {
  isShowDetailedDimensions: boolean;
  isShowNorthAngle: boolean;
  isShowBoundaries: boolean;
  parcelFollowingEnabled: boolean;
};
export type Board = MapBoard | ListingBoard;

export type BoardUser = {
  id: number;
  name: string;
  avatar: string;
};

export type MapBoard = {
  type: 'map';
  authorId: number;
  createdAt: string;
  id: string;
  name: string;
  rawData: {
    plotUuid: string;
    centerCoordinates: Position | null;
    detailedSettings: DetailedPlotSettings | null;
    layersMap: LayersEnableMap | null;
    layersAdded: {
      [BasikFeatureLayer.Basik]: boolean;
      [BasikFeatureLayer.Rdtr]: boolean;
    } | null;
    pluginsAdded: {
      reidex: boolean;
    } | null;
    style: string | null;
  };
  users: BoardUser[];
};

export type ListingBoard = {
  id: string;
  name: string;
  authorId: number;
  rawData: any[];
  type: 'listing';
  createdAt: string;
  listing: ListingInfo;
  users: BoardUser[];
};
@Injectable({
  providedIn: 'root',
})
export class BoardsApiService {
  private _baseUrl: string = `${environment.apiUrl}/api`;
  constructor(private http: HttpClient) {}

  public getBoardsList(): Observable<Board[]> {
    return this.http.post<Board[]>(`${this._baseUrl}/board/list`, {});
  }

  public updateBoard(board: Partial<Board>) {
    return this.http.post<Board>(`${this._baseUrl}/board/edit`, board);
  }

  public createBoard(board: Partial<Board>) {
    return this.http.post<Board>(`${this._baseUrl}/board/create`, board);
  }

  public deleteBoard(board: Partial<Board>) {
    return this.http.post<Board>(`${this._baseUrl}/board/delete/${board.id}`, {});
  }

  public joinById(id: string) {
    return this.http.get<unknown>(`${this._baseUrl}/board/join/${id}`);
  }

  public getBoardEntities(id: string) {
    return this.http.get<ExternalEntity[]>(`${this._baseUrl}/geometry/list/${id}`);
  }

  public createBoardEntity(entity: ExternalEntity, boardId: string) {
    const payload: ExternalEntityCreatePayload = {
      boardId,
      rawData: entity.rawData,
      geometry: entity.geometry,
    };

    return this.http.post<ExternalEntity>(`${this._baseUrl}/geometry/create`, payload);
  }

  public editBoardEntity(entity: ExternalEntity) {
    return this.http.post<ExternalEntity>(`${this._baseUrl}/geometry/edit`, entity);
  }

  public deleteBoardEntity(id: string) {
    return this.http.post<ExternalEntity>(`${this._baseUrl}/geometry/delete`, {
      id,
    });
  }
}
